import React from 'react'
import { kebabCase, upperFirst } from 'lodash'
import { Link, graphql } from 'gatsby'
import cn from 'classnames'

// Components
import { Container, Row, Col } from 'reactstrap'
import Section from '@components/Section'
import SEO from '@components/SEO'

// assets
import ideas from '@assets/images/ideas.png'

const Argomenti = ({
  pageContext: { themes },
  data: {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
    allMarkdownRemark: { group },
  },
}) => (
  <main>
    <SEO title={title} description={html} />
    <Container>
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <header className="mt-5">
            <h1 className="mb-3">{title}</h1>
          </header>
        </Col>
        <Col md={{ size: 7, offset: 1 }}>
          <div
            className="mt-5"
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </Col>
      </Row>
    </Container>
    <Section type="main">
      <img
        src={ideas}
        alt="Parole"
        className="d-none d-md-block"
        style={{
          position: 'absolute',
          top: 'auto',
          left: 'auto',
          bottom: '100%',
          right: 0,
          maxWidth: '35%',
        }}
      />
      <div className="card-columns">
        {themes.map((theme) => (
          <dl key={theme.name} className="card pr-md-4" style={{ border: 0 }}>
            <dt>
              <h3 className={cn('h5')}>
                <Link to={`/tema/${kebabCase(theme.name)}`}>{theme.name}</Link>
              </h3>
            </dt>
            <dd>
              <p className="u-text--intro">{theme.description}</p>
              <ul className="list-unstyled">
                {theme.words.map((word) => (
                  <li key={word}>
                    <Link to={`/parola/${kebabCase(word)}`}>
                      {upperFirst(word)}
                    </Link>
                  </li>
                ))}
              </ul>
            </dd>
          </dl>
        ))}
      </div>
    </Section>
  </main>
)

export default Argomenti

export const tagPageQuery = graphql`
  query TagsQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "arguments" } }) {
      id
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___words) {
        fieldValue
        totalCount
        edges {
          node {
            ...BoxFrontmatter
          }
        }
      }
    }
  }
`
